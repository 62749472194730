.overflow-x {
    overflow-x: auto;
}

.list-style-none {
    list-style-type: none;
}

.u-padding-left-bullet {
    padding-left: 3em;
}

.font-size-xs {
    font-size: 0.75em;
}

::ng-deep ul.login-title nx-icon {
    color: #006192;
}
