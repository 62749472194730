/* You can add global styles to this file, and also import other style files */
@import "@angular/cdk/overlay-prebuilt.css";
@import '@angular/cdk/a11y-prebuilt.css';
@import "@aposin/ng-aquila/css/utilities.css";
@import "@allianz/ngx-ndbx/css/themes/ndbx.css";
@import "@allianz/ndbx-styles/dist/patternlab.css";
@import "@allianz/ngx-ndbx/css/ndbx-icons.css";

@import "./assets/_utils";

nx-expansion-panel {
    div.nx-expansion-panel__content > div.nx-expansion-panel__body {
        padding-right: 2em !important;
    }

    nx-expansion-panel-header > div.nx-expansion-panel__header-content {
        > nx-expansion-panel-title.nx-expansion-panel__header-title {
            font-size: 2.2em !important;
        }

        > nx-icon {
            font-size: 2em !important;
        }
    }
}

.break-word {
    word-break: break-word;
}

.nowrap {
    white-space: nowrap;
}

nx-icon[name=product-world-globe], nx-icon[name=info-circle-o] {
    color: var(--interactive-primary)!important;
}
